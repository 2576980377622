import axios from "axios";
import router from "../router";

const axiosIns = axios.create({
	baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
	withCredentials: true,
})

// Request Interceptor
axiosIns.interceptors.request.use(
	config => {
		// Get token from localStorage
		const token = localStorage.getItem('jwt');

		// If token is present add it to request's Authorization Header
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config
	},
	error => Promise.reject(error),
)

// Add request/response interceptor
axiosIns.interceptors.response.use(
	response => response,
	error => {
		const { response } = error;

		// If token expired or invalid, log user out and redirect user to login page
		if (response && response.status === 401) {
			localStorage.removeItem('userData');

			router.push({ name: 'login' });
		}
		return Promise.reject(error);
	}
)

export default axiosIns;

<script>
import i18n from '@/i18n';

const { t: $t } = i18n.global

export default {
  name: 'FooterNavigationComponent',
  data() {
    return {
      navItems: [
        { label: $t('t-home'), icon: 'ri-home-3-line', route: '/' },
        { label: $t('t-join'), icon: 'ri-menu-line', route: '/projects' },
        { label: $t('t-shop'), icon: 'ri-shopping-bag-line', route: '/shop' },
        // { label: $t('t-my'), icon: 'ri-account-circle-line', route: '/account' },
      ]
    }
  },
  methods: {
    goToAccountPage() {
      if (this.$store.getters['auth/loggedIn']) {
        this.$router.push({ path: '/account' });
      } else {
        this.$store.dispatch('modal/toggleLoginConfirmModal', {visible: true});
      }
    },
  },
}
</script>
<template>
  <div class="navbar-tabs d-flex">
    <router-link v-for="(item, index) in navItems" :to="item.route" :key="index" class="navbar-item">
      <i class="ri-xl" :class="item.icon"></i>
      <div class="label">{{ item.label }}</div>
    </router-link>
    <a @click="goToAccountPage" class="navbar-item" :class="{ 'router-link-active router-link-exact-active': $route.name == 'account'}">
      <i class="ri-xl ri-account-circle-line"></i>
      <div class="label">{{ $t('t-my') }}</div>
    </a>
  </div>
</template>

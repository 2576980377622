import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVueNext from 'bootstrap-vue-next';
import router from "./router";
import store from "./state/store";
import axiosIns from './libs/axios';
import i18n from './i18n';
import helper from './libs/helper';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/assets/scss/app.scss';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import toastification from './libs/toastification';

const app = createApp(App);
app.use(BootstrapVueNext);
app.use(store);
app.use(router);
app.use(i18n);
app.use(helper);
app.use(Toast, toastification);
app.mount('#app');

app.config.globalProperties.$axios = {...axiosIns};
app.config.globalProperties.$currencyCode = process.env.VUE_APP_CURRENCY || 'MYR';
app.config.globalProperties.$currencySymbol = process.env.VUE_APP_CURRENCY_SYMBOL || 'MYR';

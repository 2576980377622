export default [
  {
    path: '/projects',
    name: 'projects',
    meta: {
      title: 'Join',
      authRequired: false,
    },
    component: () => import('@/views/projects/Projects'),
  },
  {
    path: '/projects/category/:slug',
    name: 'project-category',
    meta: {
      title: 'Join',
      authRequired: false,
    },
    component: () => import('@/views/projects/ProjectCategory'),
  },
  {
    path: '/projects/booking/final-payment/:slug',
    name: 'project-final-payment',
    meta: {
      title: 'Booking Final Payment',
      authRequired: true,
    },
    component: () => import('@/views/projects/BookingFinalPayment'),
  },
  {
    path: '/projects/:slug',
    name: 'project-details',
    meta: {
      title: 'Project Details',
      authRequired: true,
    },
    component: () => import('@/views/projects/ProjectDetails'),
  },
];

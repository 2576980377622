import axios from "axios";
import API from '@/libs/api';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const state = {
  config: [],
  configPromise: null,
  banners: [],
  announcements: [],
  news: [],
  newsDetails: [],
  bannersPromise: null,
  announcementsPromise: null,
  newsPromise: null,
};

export const mutations = {
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },
  SET_ANNOUNCEMENTS(state, announcements) {
    state.announcements = announcements;
  },
  SET_NEWS(state, news) {
    state.news = news;
  },
  SET_NEWS_DETAILS(state, newsDetails) {
    state.newsDetails[newsDetails.slug] = newsDetails;
  },
  // Promises
  SET_CONFIG_PROMISE(state, promise) {
    state.configPromise = promise;
  },
  SET_BANNERS_PROMISE(state, promise) {
    state.bannersPromise = promise;
  },
  SET_ANNOUNCEMENTS_PROMISE(state, promise) {
    state.announcementsPromise = promise;
  },
  SET_NEWS_PROMISE(state, promise) {
    state.newsPromise = promise;
  },
};

export const getters = {
  config(state) {
    return state.config;
  },
  banners(state) {
    return state.banners;
  },
  announcements(state) {
    return state.announcements;
  },
  news(state) {
    return state.news;
  },
};

export const actions = {
  fetchConfig({ state, commit }) {
    if (state.configPromise) {
      return state.configPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_CONFIG + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_CONFIG', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });

    commit('SET_CONFIG_PROMISE', promise);

    return promise;
  },
  // Fetch banners from JSON file.
  fetchBanners({ state, commit }) {
    if (state.bannersPromise) {
      return state.bannersPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_BANNERS + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_BANNERS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
    });

    commit('SET_BANNERS_PROMISE', promise);
    
    return promise;
  },
  // Fetch announcements from JSON file.
  fetchAnnouncements({ state, commit }) {
    if (state.announcementsPromise) {
      return state.announcementsPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_ANNOUNCEMENTS + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_ANNOUNCEMENTS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
    });

    commit('SET_ANNOUNCEMENTS_PROMISE', promise);
    
    return promise;
  },
  // Fetch news from JSON file.
  fetchNews({ state, commit }) {
    if (state.newsPromise) {
      return state.newsPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_NEWS + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_NEWS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
    });

    commit('SET_NEWS_PROMISE', promise);

    return promise;
  },
  fetchNewsDetails({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_NEWS_DETAILS.replace(':slug', slug) + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_NEWS_DETAILS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    })
  },
};

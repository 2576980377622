import { POSITION } from "vue-toastification";

const options = {
  position: POSITION.TOP_CENTER,
	hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  icon: true,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
  newestOnTop: true,
  maxToasts: 1,
}

export default options;

export default [
  {
    path: '/shop',
    name: 'shop',
    meta: {
      title: 'Shop',
      authRequired: false,
    },
    component: () => import('@/views/shop/Products'),
  },
  {
    path: '/shop/details/:slug',
    name: 'product-details',
    meta: {
      title: 'Product Details',
      authRequired: false,
    },
    component: () => import('@/views/shop/ProductDetails'),
  },
  {
    path: '/payment/:slug',
    name: 'payment-form',
    meta: {
      title: 'Product Details',
      authRequired: false,
    },
    component: () => import('@/views/shop/PaymentForm'),
  },
  {
    path: '/addresses',
    name: 'addresses',
    meta: {
      title: 'Address Selection',
      authRequired: true,
    },
    component: () => import('@/views/shop/AddressSelection'),
  },
  {
    path: '/addresses/edit',
    name: 'addresses-form',
    meta: {
      title: 'Address Form',
      authRequired: true,
    },
    component: () => import('@/views/shop/AddressForm'),
  },
  {
    path: '/orders',
    name: 'order-history',
    meta: {
      title: 'Order History',
      authRequired: true,
    },
    component: () => import('@/views/shop/OrderHistory'),
  },
];

<script>
export default {
  props: {
    title: { type: String, default: null },
    text: { type: String, default: null },
  }
}
</script>
<template>
  <div class="toastification">
    <h6 
      v-if="title"
      class="mb-0 font-weight-bold toastification-title"
      :class="`text-white`"
      v-text="title"
    ></h6>
    <small 
      v-if="text"
      class="d-inline-block text-white"
      v-html="text"
    ></small>
  </div>
</template>

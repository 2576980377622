import axios from "axios";
import axiosIns from '@/libs/axios';
import API from '@/libs/api';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const state = {
  projectCategories: [],
  projects: [],
  projectCategoriesPromise: null,
  projectsPromise: null,
};

export const mutations = {
  SET_PROJECT_CATEGORIES(state, categories) {
    state.projectCategories = categories;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  // Promises
  SET_PROJECT_CATEGORIES_PROMISE(state, promise) {
    state.projectCategoriesPromise = promise;
  },
  SET_PROJECTS_PROMISE(state, promise) {
    state.projectsPromise = promise;
  },
};

export const getters = {
  projectCategories(state) {
    return state.projectCategories;
  },
  projects(state) {
    return state.projects;
  },
};

export const actions = {
  // Fetch project categories from JSON file.
  fetchProjectCategories({ state, commit }) {
    if (state.projectCategoriesPromise) {
      return state.projectCategoriesPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_PROJECT_CATEGORIES + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_PROJECT_CATEGORIES', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });

    commit('SET_PROJECT_CATEGORIES_PROMISE', promise);
    
    return promise;
  },
  // Fetch projects from JSON file.
  fetchProjects({ state, commit }) {
    if (state.projectsPromise) {
      return state.projectsPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_PROJECTS + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_PROJECTS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });

    commit('SET_PROJECTS_PROMISE', promise);
    
    return promise;
  },
  // Fetch project details
  fetchProjectDetails(_, slug) {
    return new Promise((resolve, reject) => {
      axiosIns.get(API.API_PROJECT_DETAILS.replace(':slug', slug))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
};

export const state = {
  showLoginConfirm: false,
};

export const mutations = {
  SET_LOGIN_CONFIRM_VISIBILITY(state, visible) {
    state.showLoginConfirm = visible;
  },
};

export const getters = {
  showLoginConfirm(state) {
    return state.showLoginConfirm
  },
};

export const actions = {
  toggleLoginConfirmModal({ commit }, { visible }) {
    commit('SET_LOGIN_CONFIRM_VISIBILITY', visible);
  },
};

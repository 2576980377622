import axiosIns from '@/libs/axios';
import api from '@/libs/api';

export const state = {
    token: localStorage.getItem('jwt'),
    currentUser: localStorage.getItem('userData'),
}

export const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
        saveState('jwt', token, false);
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('userData', newValue);
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        if (typeof state.currentUser === 'object') {
            return state.currentUser;
        } else {
            return JSON.parse(state.currentUser)
        }
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('fetchUserData')
    },

    // Register
    register(_, { username, password, password_confirmation, invitation_code }) {
        return new Promise((resolve, reject) => {
            axiosIns.post(api.API_REGISTER, {
                username: username,
                password: password,
                password_confirmation: password_confirmation,
                invitation_code: invitation_code,
            })
            .then(async (response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },

    // Logs in the current user.
    logIn({ commit, dispatch }, { username, password } = {}) {
        // if (getters.loggedIn) return dispatch('validate')

        return new Promise((resolve, reject) => {
            axiosIns.post(api.API_LOGIN, {
                username: username,
                password: password,
            })
            .then(async (response) => {
                const result = response.data.data;
                commit('SET_TOKEN', result.token);

                await dispatch('fetchUserData');

                resolve(response)
            })
            .catch((error) => {
                reject(error);
            })
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            axiosIns.post(api.API_LOGOUT)
            .then((response) => {
                commit('SET_TOKEN', null);
                commit('SET_CURRENT_USER', null);

                resolve(response);
            })
            .catch((error) => {
                reject(error);
            })
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        const user = JSON.parse(localStorage.getItem('userData'));
        if (!state.currentUser) return Promise.resolve(null);
        commit('SET_CURRENT_USER', user)
        return user;
    },

    // Get current user data
    fetchUserData({ commit }) {
        return new Promise((resolve, reject) => {
            axiosIns.get(api.API_PROFILE)
            .then((response) => {
                const userData = response.data.data;
                commit('SET_CURRENT_USER', userData);

                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
}

// ===
// Private helpers
// ===

function saveState(key, state, isJson=true) {
    if (isJson) {
        localStorage.setItem(key, JSON.stringify(state));
    } else {
        localStorage.setItem(key, state);
    }
}

<script>
import FooterNavigation from './components/FooterNavigation';

export default {
  name: 'App',
  components: {
    FooterNavigation,
  },
  data() {
    return {
      showNavigation: true,
    }
  },
  computed: {
    showLoginConfirm() {
      return this.$store.getters['modal/showLoginConfirm']
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onRoutechange(ele) {
      if (ele.name == 'login' || ele.name == 'register') {
        this.showNavigation = false;
      } else {
        this.showNavigation = true;
      }
    },
    goToLogin() {
      this.$router.push({ path: '/login' });
    },
    hidden() {
      this.$store.dispatch('modal/toggleLoginConfirmModal', {visible: false});
    },
  }
}
</script>
<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'" mode="in">
      <component :is="Component" :key="$route.name" />
    </transition>
  </router-view>
  
  <BModal 
    ref="loginConfirmationModal" 
    v-model="showLoginConfirm"
    class="v-modal-custom" 
    :title="$t('t-tip')" 
    :hide-header-close="true"
    :ok-title="$t('t-go-to-login')"
    @ok="goToLogin"
    @hidden="hidden"
    centered
  >
    {{ $t('t-please-login-first') }}
  </BModal>
  
  <FooterNavigation v-if="showNavigation"/>
</template>

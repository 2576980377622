export default [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home',
      authRequired: false,
    },
    component: () => import('@/views/Home'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login',
      authRequired: false,
    },
    component: () => import('@/views/account/Login'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: 'Register',
      authRequired: false,
    },
    component: () => import('@/views/account/Register'),
  },
  {
    path: '/notice/:id',
    name: 'notice-details',
    meta: {
      title: 'Notice Details',
      authRequired: false,
    },
    component: () => import('@/views/NoticeDetails'),
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: 'News',
      authRequired: false,
    },
    component: () => import('@/views/news/News'),
  },
  {
    path: '/news/:slug',
    name: 'news-details',
    meta: {
      title: 'News Details',
      authRequired: false,
    },
    component: () => import('@/views/news/Details'),
  },
];

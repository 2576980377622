import axios from "axios";
import axiosIns from '@/libs/axios';
import API from '@/libs/api';

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const state = {
  products: [],
  selectedProduct: null,
  productsPromise: null,
  addresses: [],
  selectedAddress: null,
};

export const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_SELECTED_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses;
  },
  SET_SELECTED_ADDRESS(state, address) {
    state.address = address;
  },
  // Promises
  SET_PRODUCTS_PROMISE(state, promise) {
    state.productsPromise = promise;
  },
};

export const getters = {
  products(state) {
    return state.products;
  },
  selectedProduct(state) {
    return state.selectedProduct;
  },
  addresses(state) {
    return state.addresses;
  },
  selectedAddress(state) {
    return state.address;
  },
};

export const actions = {
  // Fetch products from JSON file.
  fetchProducts({ state, commit }) {
    if (state.productsPromise) {
      return state.productsPromise;
    }

    const promise = new Promise((resolve, reject) => {
      axios.get(BASE_URL + API.JSON_PRODUCTS + '?v=' + Math.floor(Date.now() / 1000))
      .then((response) => {
        commit('SET_PRODUCTS', response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });

    commit('SET_PRODUCTS_PROMISE', promise);
    
    return promise;
  },
  // Fetch product details
  fetchProductDetails(_, slug) {
    return new Promise((resolve, reject) => {
      axiosIns.get(API.API_PRODUCT_DETAILS.replace(':slug', slug))
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  // Fetch member's addresses
  fetchAddresses({ commit }) {
    return new Promise((resolve, reject) => {
      axiosIns.get(API.API_ADDRESSES)
      .then((response) => {
        commit('SET_ADDRESSES', response.data.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  // Set selected product
  setSelectedProduct({ commit }, product) {
    commit('SET_SELECTED_PRODUCT', product);
  },
  // Set selected address
  setSelectedAddress({ commit }, address) {
    commit('SET_SELECTED_ADDRESS', address);
  },
};
